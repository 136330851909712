import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
      domain="dev-g11osle77cypjnvf.us.auth0.com"
      clientId="JWiOtf4OszZT3XmJ3dkTGLAS9PtW0qge"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://dev-g11osle77cypjnvf.us.auth0.com/api/v2/",
        scope: "read:current_user update:current_user_metadata"
      }}
    >
  <Router>
    <App />
  </Router>
  </Auth0Provider>,
);