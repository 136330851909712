import React, { useEffect, useState } from 'react';
import { useDeckContext } from './DeckContext';


const SubscriptionSetup = () => {
    const { makeAuthenticatedRequest } = useDeckContext();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [cancelUrl, setCancelUrl] = useState('');
  
    // FIXME: This component would run faster if we mananged authentication state globally
    useEffect(() => {
        makeAuthenticatedRequest('/api/create-checkout-session', 'POST', null)
            .then(data => {
            setCheckoutUrl(data.checkoutUrl);
            setCancelUrl(data.cancelUrl);
            setLoading(false);
            })
            .catch(error => {
            console.error('Error creating checkout session:', error);
            setError('Failed to create checkout session. Please try again.');
            setLoading(false);
            });
    }, [makeAuthenticatedRequest]);
  
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  
    return (
      <div>
        <h2>Subscribe to MyFlashpal</h2>
        {checkoutUrl ? (
          <div>
            <button onClick={() => window.location.href = checkoutUrl}>Proceed to Checkout</button>
            <button onClick={() => window.location.href = cancelUrl}>No Thanks</button>
          </div>
        ) : (
          <p>Unable to initiate Stripe checkout. Please try again or contact support if the problem persists.</p>
        )}
      </div>
    );
  };
  
  export default SubscriptionSetup;