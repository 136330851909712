// src/components/LandingPage.js

import React from 'react';
import './LandingPage.css';

// TODO: Add marketing for product and a subscribe button to /post-signup (SubscriptionSetup.js)
const LandingPage = () => {
  return (
    <div className="landing">
        <h1>Welcome to MyFlashPal</h1>
        <p>Start your learning journey with us!</p>
        {/* More content here */}
    </div>
  );
};

export default LandingPage;
