// src/components/Settings.js
import React, { useEffect } from 'react';
import './Settings.css'

const Settings = ({ refreshUserData, currentUser }) => {
  // state goes here if we need it

  const isSubscriptionActive = (expiresAt) => { return expiresAt && new Date(expiresAt) > new Date(); };
  
  useEffect(() => { refreshUserData(); }, [refreshUserData])

  return (
    <div className="settings">
      <h2>Settings</h2>
      {currentUser ? (
        <div>
          <p>Email: {currentUser.email}</p>
          <p>
            Subscription Status:{' '}
            {isSubscriptionActive(currentUser.subscriptionExpiresAt)
              ? 'Active'
              : 'Non-active'
            }
          </p>
          {isSubscriptionActive(currentUser.subscriptionExpiresAt)
            ? `Subscription Automatically Renews: ${new Date(currentUser.subscriptionExpiresAt).toDateString()}`
            : ''
          }
          <p>Credits: ${currentUser.currentCredits}</p>
        </div>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};


export default Settings;