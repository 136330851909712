// src/components/DeckPage.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DeckChat from './DeckChat';
import DeckLatex from './DeckLatex';
import { useDeckContext } from './DeckContext';
import './DeckPage.css';

const DeckPage = ({ isCollapsed, isOnMobile }) => {
    const { deckId } = useParams();
    const {
        decks,
        currentDeck,
        setCurrentDeck,
        combinedCards,
        userStudying,
        setUserStudying,
        handleStudySessionToggle,
        currentCardIndex,
        setCurrentCardIndex,
        setShowFront,
        setRawLatex,
        setRenderedLatex,
        editedFrontLatexContent,
        editedBackLatexContent,
        renderLatex,
    } = useDeckContext();

    // Change deck-related state variables when deckId changes
    useEffect(() => {
        const deck = decks.find(d => d._id === deckId);
        setCurrentCardIndex(0);
        setShowFront(true);
        setCurrentDeck(deck);
    }, [deckId, decks, setCurrentCardIndex, setShowFront, setCurrentDeck]);

    useEffect(() => {
        if (currentDeck) {
            setUserStudying(currentDeck.studySession?.isActive || false);
        }
    }, [currentDeck, setUserStudying]);

    // Change card-related state variables when currentCardIndex changes or when content is edited
    useEffect(() => {
        if (combinedCards.length > 0) {
            const cardToShow = combinedCards[currentCardIndex];
            if (!editedFrontLatexContent && !editedBackLatexContent) setShowFront(true);
            setRawLatex(cardToShow);
            renderLatex(cardToShow);
        } else {
            setRawLatex({ front: null, back: null });
            setRenderedLatex({ front: null, back: null });
            setCurrentCardIndex(0);
        }
    }, [currentCardIndex, combinedCards, renderLatex, editedBackLatexContent, editedFrontLatexContent, setShowFront, setRawLatex, setRenderedLatex, setCurrentCardIndex]);

    return (
        <div className="deck-page">
            <div className="mode-toggle-container">
                <button
                    className="mode-toggle-button"
                    onClick={() => handleStudySessionToggle(deckId, false)}
                    disabled={!userStudying}
                >Create</button>
                <button
                    className="mode-toggle-button"
                    onClick={() => handleStudySessionToggle(deckId, true)}
                    disabled={userStudying}
                >Study</button>
            </div>
            {!userStudying && (
                <DeckChat
                    isOnMobile={isOnMobile}
                    isCollapsed={isCollapsed}
                />
            )}
            <DeckLatex />
        </div>
    );
};

export default DeckPage;