// Submenu.js
import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';

const Submenu = forwardRef(({ children, position, className }, ref) => {
  // Style ONLY for positioning the submenu
  const style = {
    top: position.top, // These values should be calculated based on the triggering element
    left: position.left
    // ...actual styling is in *.css files using className prop
  };

  return ReactDOM.createPortal(
    <div ref={ref} className={className} style={style}>
      {children}
    </div>,
    document.getElementById('portal-root')
  );
});

export default Submenu;