// DeckLatex.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { creationTempCard } from './creationTempCard';
import Submenu from './Submenu';
import { useDeckContext } from './DeckContext';
import './DeckLatex.css';

const DeckLatex = () => {
    const {
        userStudying,
        combinedCards,
        currentCardIndex,
        setCurrentCardIndex,
        tempCard,
        setTempCard,
        showFront,
        setShowFront,
        renderedLatex,
        rawLatex,
        setEditedFrontLatexContent,
        setEditedBackLatexContent,
        startFullDeckStudySession,
        getNextRepetitionDueDate,
        currentDeck,
        makeAuthenticatedRequest,
        getAccessTokenSilently,
        fetchDecks
    } = useDeckContext();

    const cardRef = useRef(null);
    const frontContentRef = useRef(null);
    const backContentRef = useRef(null);

    const deleteButtonRef = useRef(null);
    const submenuRef = useRef(null);

    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteConfirmationPosition, setDeleteConfirmationPosition] = useState({ top: 0, left: 0 });

    const ActionButton = ({ currentCardIndex, tempCard, handleSaveCard, openSubmenu }) => {
        if (currentCardIndex === 0 && tempCard._id === 'c-temp') {
            return (
                <button className="creation-pseudobutton">
                    Create a new Flashcard!
                </button>
            );
        } else if (currentCardIndex === 0 && tempCard._id !== 'c-temp') {
            return (
                <button onClick={handleSaveCard}>
                    Save Card
                </button>
            );
        } else if (currentCardIndex > 0) {
            return (
                <button
                    ref={deleteButtonRef}
                    className="delete-button"
                    onClick={openSubmenu}
                >
                    Delete Card
                </button>
            );
        }
        return null;
    };

    const getDeleteConfirmationPosition = () => {
        if (cardRef.current) {
            const cardRect = cardRef.current.getBoundingClientRect();
            return {
                top: cardRect.bottom,
                left: cardRect.left + (cardRect.width / 2),
            };
        }
        return deleteConfirmationPosition;
    };

    const openSubmenu = () => {
        const position = getDeleteConfirmationPosition();
        setDeleteConfirmationPosition(position);
        setDeleteConfirmationVisible(true);
    };


    const findOptimalFontSize = useCallback((contentRef, cardRef) => {
        const contentFits = (fontSize) => {
            if (!contentRef.current || !cardRef.current) return false;
    
            const katexElement = contentRef.current.querySelector('.katex');
            if (!katexElement) return false;
    
            katexElement.style.fontSize = `${fontSize * cardRef.current.clientWidth / 2400}em`;
    
            // Force a reflow
            void contentRef.current.offsetHeight;
    
            const scrollHeight = katexElement.scrollHeight;
            const fits = scrollHeight <= cardRef.current.clientHeight - 20;
            
            console.log(`Font size ${fontSize}em - Fits: ${fits}`,
                        `ScrollHeight: ${scrollHeight}`,
                        `ClientHeight: ${cardRef.current.clientHeight - 20}`);
            return fits;
        };
    
        const binarySearch = (low, high) => {
            if (high - low < 0.05) return low;
    
            const mid = (low + high) / 2;
            if (contentFits(mid)) {
                return binarySearch(mid, high);
            } else {
                return binarySearch(low, mid);
            }
        };
    
        let optimalSize = binarySearch(0.1, 10);
        console.log(`Initial optimal font size: ${optimalSize}em`);
    
        // Verification step
        const verifyAndAdjust = (size) => {
            if (!contentFits(size)) {
                while (size > 0.1 && !contentFits(size)) {
                    size -= 0.05;
                }
            }
            return size;
        };
    
        optimalSize = verifyAndAdjust(optimalSize);
        console.log(`Verified optimal font size: ${optimalSize}em`);
    
        const katexElement = contentRef.current.querySelector('.katex');
        if (katexElement) {
            katexElement.style.fontSize = ``;
        }
    
        contentRef.current.style.setProperty('--font-size', `${optimalSize}em`);
    
        // Final check
        const finalScrollHeight = katexElement ? katexElement.scrollHeight : contentRef.current.scrollHeight;
        console.log(`Final scroll height: ${finalScrollHeight}, Client height: ${cardRef.current.clientHeight}`);
    
        return optimalSize; // return the current clientWidth too so we can set denominator in scaling
    }, []);

    const calculateFontSize = useCallback((face) => {
        const contentRef = face === 'front' ? frontContentRef : backContentRef;
        if (contentRef.current && cardRef.current) {
            console.log(`Testing ${face} content font sizes:`);
            const katexElement = contentRef.current.querySelector('.katex');
            console.log("SCROLL HEIGHT: before findOptimalFontSize():", katexElement ? katexElement.scrollHeight : contentRef.current.scrollHeight);
            findOptimalFontSize(contentRef, cardRef);
            console.log("SCROLL HEIGHT: after findOptimalFontSize():", katexElement ? katexElement.scrollHeight : contentRef.current.scrollHeight);
        }
    }, [findOptimalFontSize]);

    useEffect(() => {
        const observeContent = (contentRef, face) => {
            if (!contentRef.current) return () => {};

            const observer = new MutationObserver((mutations) => {
                for (let mutation of mutations) {
                    if (mutation.type === 'childList') {
                        const katexElement = contentRef.current.querySelector('.katex');
                        if (katexElement) {
                            observer.disconnect();
                            calculateFontSize(face);
                            break;
                        }
                    }
                }
            });

            observer.observe(contentRef.current, { childList: true, subtree: true });
            return () => observer.disconnect();
        };

        const frontObserver = observeContent(frontContentRef, 'front');
        const backObserver = observeContent(backContentRef, 'back');

        return () => {
            frontObserver();
            backObserver();
        };
    }, [currentCardIndex, calculateFontSize]);

    // Effect to handle font size calculation when switching faces
    useEffect(() => {
        const contentRef = showFront ? frontContentRef : backContentRef;
        const face = showFront ? 'front' : 'back';
        
        if (contentRef.current && contentRef.current.querySelector('.katex')) {
            calculateFontSize(face);
        }
    }, [showFront, calculateFontSize]);

    // Function to adjust the scale of KaTeX content based on the container width
    const adjustKaTeXScale = useCallback(() => {
        if (cardRef.current) {
            const containerWidth = cardRef.current.clientWidth;
            const scaleFactor = containerWidth / 2400;
            document.documentElement.style.setProperty('--scale-factor', scaleFactor);
        }
    }, []);

    // Set up ResizeObserver to listen for changes in the size of the latex-content element
    useEffect(() => {
        const latexContent = document.querySelector('.latex-content');
        if (!latexContent) return;
        const resizeObserver = new ResizeObserver(() => adjustKaTeXScale());
        resizeObserver.observe(latexContent);
        return () => { resizeObserver.disconnect(); };
    }, [adjustKaTeXScale]);

    const handleRecallResponse = async (responseQuality) => {
        try {
            const currentCardId = combinedCards[currentCardIndex]._id;
            await makeAuthenticatedRequest(
                `/api/decks/${currentDeck._id}/cards/${currentCardId}/repetition`,
                'PUT',
                { responseQuality },
                getAccessTokenSilently
            );
            if (currentCardIndex !== 0) setCurrentCardIndex(0); // We always want index to rest at 0 when studying, queue logic
            await fetchDecks();
        } catch (error) {
            console.error('Error handling repetition:', error);
        }
    };

    const handleDeleteCard = async () => {
        const currentCardId = combinedCards[currentCardIndex]._id;
        if (!currentCardId) {
            console.error('No card selected for deletion');
            return;
        }

        try {
            setDeleteConfirmationVisible(false);
            await makeAuthenticatedRequest(`/api/decks/${currentDeck._id}/cards/${currentCardId}`, 'DELETE', {}, getAccessTokenSilently);
            await fetchDecks();
            // If the last card was deleted, show the previous card. Otherwise, maintain the current index
            setCurrentCardIndex(prevIndex => prevIndex >= currentDeck.cards.length - 1 ? Math.max(prevIndex - 1, 0) : prevIndex);
        } catch (error) {
            console.error('Error deleting card:', error);
        }
    };

    // Card traversal functions
    const goToNextCard = () => {
        if (!showFront) {
            flipCard();
            // Add a delay of 0.100 seconds
            setTimeout(() => {
                setCurrentCardIndex((prevIndex) => (prevIndex + 1) % combinedCards.length);
                setEditedBackLatexContent(null);
                setEditedFrontLatexContent(null);
            }, 100);
        } else {
            setCurrentCardIndex((prevIndex) => (prevIndex + 1) % combinedCards.length);
            setEditedBackLatexContent(null);
            setEditedFrontLatexContent(null);
        }
    };

    const goToPreviousCard = () => {
        if (!showFront) {
            flipCard();
            // Add a delay of 0.100 seconds
            setTimeout(() => {
                setCurrentCardIndex((prevIndex) => (prevIndex - 1 + combinedCards.length) % combinedCards.length);
                setEditedBackLatexContent(null);
                setEditedFrontLatexContent(null);
            }, 100);
        } else {
            setCurrentCardIndex((prevIndex) => (prevIndex - 1 + combinedCards.length) % combinedCards.length);
            setEditedBackLatexContent(null);
            setEditedFrontLatexContent(null);
        }
    };

    const flipCard = () => { setShowFront(prevShowFront => !prevShowFront); };

    const handleSaveCard = async () => {
        try {
            await makeAuthenticatedRequest(`/api/decks/${currentDeck._id}/cards`, 'POST', { latexContent: rawLatex, messages: tempCard.messages, tempCardId: tempCard._id }, getAccessTokenSilently);
            setTempCard(creationTempCard);
            setEditedBackLatexContent(null);
            setEditedFrontLatexContent(null);
            await fetchDecks();
        } catch (error) {
            console.error('Error saving card:', error);
        }
    };

    // Collapse delete-doublecheck submenu when click outside it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                deleteConfirmationVisible &&
                submenuRef.current &&
                !submenuRef.current.contains(event.target) &&
                deleteButtonRef.current &&
                !deleteButtonRef.current.contains(event.target)
            ) {
                setDeleteConfirmationVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [deleteConfirmationVisible]);

    return (
        <div className="deck-latex">
            {(userStudying && combinedCards.length === 0) ? (
                <div className="full-deck-session-container">
                    <div className="full-deck-session-content">
                        <h2>No more cards left for today's session!</h2>
                        <p>Do you want to start a full deck study session?</p>
                        <button onClick={() => startFullDeckStudySession(currentDeck._id)}>Start Full Deck Session</button>
                    </div>
                </div>
            ) : (
                <div className="card-container">
                    <div
                        ref={cardRef}
                        className={`latex-content ${!showFront ? 'flipped' : ''} ${userStudying ? 'studying' : 'creating'}`}
                        onClick={userStudying ? (showFront ? flipCard : void (0)) : flipCard}
                    >
                        <div ref={frontContentRef} className="card-face card-front" dangerouslySetInnerHTML={{ __html: renderedLatex.front }} />
                        <div ref={backContentRef} className="card-face card-back" dangerouslySetInnerHTML={{ __html: renderedLatex.back }} />
                    </div>
                    {(userStudying) ? (
                        (showFront) ? (
                            <div className="study-progress">
                                <p>{combinedCards.length} cards left</p>
                                <button onClick={() => flipCard()}>Show Answer</button>
                            </div>
                        ) : (
                            <div className="recall-strength-input">
                                <h3>Rate your recall strength:</h3>
                                <h4>Current Interval: {getNextRepetitionDueDate(combinedCards[currentCardIndex]).toString()} days</h4>
                                <div className="recall-strength-buttons">
                                    <span className="recall-strength-buttons-group again">
                                        <span className="recall-strength-buttons-wrapper">
                                            <button onClick={() => handleRecallResponse(0)}>0</button>
                                            <button onClick={() => handleRecallResponse(1)}>1</button>
                                            <button onClick={() => handleRecallResponse(2)}>2</button>
                                            <button onClick={() => handleRecallResponse(3)}>3</button>
                                        </span>
                                        <span className="recall-strength-label">Again</span>
                                    </span>
                                    <span className="recall-strength-buttons-group later">
                                        <span className="recall-strength-buttons-wrapper">
                                            <button onClick={() => handleRecallResponse(4)}>4</button>
                                            <button onClick={() => handleRecallResponse(5)}>5</button>
                                        </span>
                                        <span className="recall-strength-label">Later</span>
                                    </span>
                                </div>
                            </div>
                        )
                    ) : null}
                </div>
            )}
            {!userStudying && (
                <div className="buttons-container">
                    {combinedCards.length > 1 && (
                        <>
                            <button onClick={goToPreviousCard}>Previous Card</button>
                            <ActionButton
                                currentCardIndex={currentCardIndex}
                                tempCard={tempCard}
                                handleSaveCard={handleSaveCard}
                                openSubmenu={openSubmenu}
                            />
                            <button onClick={goToNextCard}>Next Card</button>
                        </>
                    )}
                    {combinedCards.length <= 1 && (
                        <ActionButton
                            currentCardIndex={currentCardIndex}
                            tempCard={tempCard}
                            handleSaveCard={handleSaveCard}
                            openSubmenu={openSubmenu}
                        />
                    )}
                    {deleteConfirmationVisible && (
                        <Submenu ref={submenuRef} className="submenu-card" position={deleteConfirmationPosition}>
                            <p>Are you sure?</p>
                            <button style={{ color: "rgb(221, 82, 76)" }} onClick={handleDeleteCard}>Yes, Delete</button>
                            <button onClick={() => setDeleteConfirmationVisible(false)}>Cancel</button>
                        </Submenu>
                    )}
                </div>
            )}
        </div>
    );
};

export default DeckLatex;