import React, { useState, useRef } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-latex';
import 'ace-builds/src-noconflict/theme-monokai';
import './CodeBlock.css';

const CodeBlock = ({ language, children, onChange, readOnly }) => {
  const editorRef = useRef(null);
  const [editedCode, setEditedCode] = useState(children);

  const handleChange = (newValue) => {
    setEditedCode(newValue);
    onChange(newValue);
  };

  const copyToClipboard = () => {
    const editor = editorRef.current;
    if (editor) {
      const code = editor.props.value;
      navigator.clipboard.writeText(code);
    }
  };

  return (
    <div className="code-block-container">
      <div className="code-block-header">
        <span>{language}</span>
        <button onClick={copyToClipboard} className="copy-button">
          Copy
        </button>
      </div>
      <AceEditor
        ref={editorRef}
        mode={language}
        theme="monokai"
        value={editedCode}
        onChange={handleChange}
        name="code-editor"
        editorProps={{ $blockScrolling: true }}
        wrapEnabled={true}
        showPrintMargin={false}
        width={'100%'}
        height={'10em'}
        fontSize={'1em'}
        readOnly={readOnly}
      />
    </div>
  );
};

export default CodeBlock;