// src/App.js

import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import './App.css';

import { DeckProvider, useDeckContext } from './components/DeckContext';
import LandingPage from './components/LandingPage';
import NavBar from './components/NavBar';
import DeckPage from './components/DeckPage';
import Settings from './components/Settings'
import SubscriptionSetup from './components/SubscriptionSetup';

const MOBILE_BREAKPOINT = 768; // pixels

// This component wraps the app content and uses the DeckContext
const AppContent = ({ children }) => {
  const { fetchDecks, isLoading, makeAuthenticatedRequest, isAuthenticated } = useDeckContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= MOBILE_BREAKPOINT);
  const [isPostSignUp, setIsPostSignUp] = useState(location.pathname === '/post-signup');
  const [isOnMobile] = useState(window.innerWidth <= MOBILE_BREAKPOINT);

  const fetchUserData = useCallback(async () => {
    try {
      const userData = await makeAuthenticatedRequest('/api/user', 'GET');
      setCurrentUser(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [makeAuthenticatedRequest]);

  const isSubscriptionActive = useCallback((expiresAt) => {
    return expiresAt && new Date(expiresAt) > new Date();
  }, []);

  // Hook to fetch user data when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated, fetchUserData]);

  // Hook to fetch decks when user data is available and subscription is active
  useEffect(() => {
    if (currentUser && isSubscriptionActive(currentUser.subscriptionExpiresAt)) {
      fetchDecks();
    } else if (currentUser && !isSubscriptionActive(currentUser.subscriptionExpiresAt) && !isPostSignUp) {
      navigate('/post-signup');
    }
  }, [currentUser, fetchDecks, isSubscriptionActive, isPostSignUp, navigate]);

  // Hook to collapse navbar on certain routes and based on user subscription
  useEffect(() => {
    setIsPostSignUp(location.pathname === '/post-signup');
    setIsCollapsed(
      isPostSignUp || 
      window.innerWidth <= MOBILE_BREAKPOINT || 
      (isAuthenticated && currentUser && !isSubscriptionActive(currentUser.subscriptionExpiresAt))
    );
  }, [location.pathname, isPostSignUp, isAuthenticated, currentUser, isSubscriptionActive]);

  // Hook to create custom vh unit for innerHeight
  useEffect(() => {
    const adjustHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', adjustHeight);
    adjustHeight();

    return () => window.removeEventListener('resize', adjustHeight);
  }, []);

  return (
    <div className="App">
      {!isPostSignUp && (
        <NavBar
          isLoading={isLoading}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}

      <div className={`content ${isCollapsed ? 'collapsed' : ''}`}>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          
          {/* Single Dynamic Route for all Decks */}
          <Route
            path="/deck/:deckId"
            element={
              <DeckPage
                isCollapsed={isCollapsed}
                isOnMobile={isOnMobile}
              />
            }
          />

          <Route 
            path="/settings"
            element={
              <Settings
                refreshUserData={fetchUserData}
                currentUser={currentUser}
              />
            }
          />

          <Route 
            path="/post-signup"
            element={
              <SubscriptionSetup />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

// The main App component now just provides the DeckContext
function App() {
  return (
    <DeckProvider>
      <AppContent />
    </DeckProvider>
  );
}

export default App;